import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ProductIcon from '../../utils/ProductIcon';

const BetterDocsAIChatbot = ({
	id,
	cartData,
	removeFromCart,
	cartLoading,
	addToCart,
}) => {
	const betterDocsAI = useMemo(() => {
		if (!cartData) {
			return null;
		}

		const _cartData = cartData.details.map((i, idx) => ({
			...i,
			index: idx,
		}));

		return _cartData?.find(item => +item.id === +id) ?? null;
	}, [cartData]);

	const [priceId, setPriceId] = useState(1);
	const [typeYearly, setTypeYearly] = useState(1);
	const [checked, setChecked] = useState(betterDocsAI ? true : false);

	useEffect(() => {
		// Load Product Details
	}, []);

	useEffect(() => {
		let priceId = betterDocsAI?.item_number?.options?.price_id;
		if (betterDocsAI && priceId) {
			setTypeYearly(+priceId);
			setPriceId(+priceId);
		}
	}, [betterDocsAI]);

	const addProductInCart = useCallback(
		(priceId, action = false) => {
			let product = {
				download_id: id,
				price_id: priceId,
			};

			if (action) {
				product.action = 'update';
			}

			addToCart(product, true, false);
		},
		[addToCart]
	);

	useEffect(() => {
		if (checked && betterDocsAI === null) {
			addProductInCart(typeYearly);
		} else if (!checked && betterDocsAI && betterDocsAI?.index) {
			removeFromCart(betterDocsAI?.index);
		}
	}, [checked, typeYearly]);

	useEffect(() => {
		if (betterDocsAI && typeYearly !== +priceId) {
			addProductInCart(typeYearly, true);
		}
	}, [typeYearly, betterDocsAI]);

	useEffect(() => {
		if (betterDocsAI == null) {
			setChecked(false);
		}
	}, [betterDocsAI]);

	return (
		<div className="WpdCheckoutProductHighlightedWrapper">
			<div className="WpdCheckoutProduct">
				<label className="WpdCheckbox">
					<input
						type="checkbox"
						checked={checked}
						onChange={e => setChecked(isChecked => !isChecked)}
					/>
					<span className="WpdText" />
				</label>
				<div className="WpdCheckoutProductInfo">
					<span className="WpdCheckoutProductIcon">
						<ProductIcon
							icon={
								betterDocsAI?.item_number?.image ??
								'https://staging.api.wpdeveloper.com/wp-content/uploads/edd/2024/12/betterdocs-chatbot-ai.png'
							}
							name="BetterDocs AI Chatbot"
						/>
					</span>
					<span className="WpdCheckoutProductDetails">
						<h4 className="WpdCheckoutProductTitle">
							BetterDocs AI Chatbot
						</h4>
						<span className="WpdCheckoutProductSubTitle">
							<strong>
								{betterDocsAI?.item_number?.price_name}
							</strong>
						</span>
					</span>
				</div>
				<div className="WpdCheckoutProductPriceWrapper">
					<label className="WpdCheckoutTogglerWrapper">
						<span
							className={`WpdCheckoutTogglerText ${typeYearly === 1 ? 'active' : ''}`}
						>
							Monthly
						</span>
						<span className="WpdCheckoutToggler">
							<input
								type="checkbox"
								checked={typeYearly === 2}
								onChange={e =>
									setTypeYearly(e.target.checked ? 2 : 1)
								}
							/>
							<span className="WpdCheckoutTogglerInner" />
						</span>
						<span
							className={`WpdCheckoutTogglerText ${typeYearly === 2 ? 'active' : ''}`}
						>
							Yearly
						</span>
					</label>
					<div className="WpdCheckoutProductPrice">
						$
						{betterDocsAI?.price ??
							(typeYearly === 1 ? '8.99' : '69.00')}{' '}
						<small className="WpdPriceDuration">
							/{typeYearly === 1 ? 'month' : 'year'}
						</small>
					</div>
				</div>
				{/* <button
					className="WpdRemoveButton WpdHoverWarning"
					onClick={() => {}}
				>
					<span className="WpdRemoveIcon">
						<i className="wpd-icon wpd-cancel" />
					</span>
				</button> */}
			</div>
		</div>
	);
};

export default BetterDocsAIChatbot;
